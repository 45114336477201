

































import {Component, Mixins} from 'vue-property-decorator'
import FormMixin from '@/general/mixins/FormMixin'
import AuthenticationService from '@/general/services/AuthenticationService'
import {validator} from '@/modules/users/models/User'
import axios from '@/plugins/axios'

@Component
export default class Login extends Mixins(FormMixin, AuthenticationService) {
  rememberMe: boolean = true
  resetPasswordText = 'Reset password'

  validator(): any {
    const customValidator = validator
    customValidator.password.regex = ''
    return customValidator
  }

  requestPasswordReset() {
    this.$router.push({ name: 'forgotPassword' })
  }

  submit() {
    this.login(this.rememberMe)
  }
}
